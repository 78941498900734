import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import html2pdf from "html2pdf.js";
import { getBrandDetailApi, getPoster } from "../reduxToolKit/slices/BrandDetail";
import { useDispatch } from "react-redux";

const ShareDealsPage = () => {
  const { state } = useLocation();
  const dispatch = useDispatch()
  const [poster, setPoster] = useState('')
  console.log('state in share deals page', state);

  const downloadPoster = () => {
    const posterImg = document.getElementById("poster");
    if (!posterImg) {
      console.error("Poster element not found.");
      return;
    }

    const pdfOptions = {
      margin: 10,
      filename: "downloaded-file.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 3, useCORS: true }, // Handle CORS
      jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
    };

    setTimeout(() => {
      html2pdf().from(posterImg).set(pdfOptions).save();
    }, 100); // Ensure rendering before capture
  };
  useEffect(() => {
    dispatch(getBrandDetailApi()).then((res) => {
      console.log('res', res);
      if (res?.payload?.status === 200) {
        const data = {
          image: res?.payload?.data?.[0]?.logo,
          companyName: res?.payload.data[0].companyName,
          name: res?.payload?.data?.[0]?.emailId,
          phoneNumber: res?.payload?.data?.[0]?.contactNumber
        }
        dispatch(getPoster(data)).then((res) => {
          console.log('poster api', res)
          setPoster(res?.payload?.data)
        })
      }
    })
  }, [])
  return (
    <div>
      <section>
        <div className="ShareThisArea">
          <div className="container">
            <div className="ContactFormBody BrandDetails">
              <form>
                <div className="row">
                  <div className="col-md-7">
                    <div className="EnterCustomerDetails" id="poster">
                      <figure>
                        <img src={state?.flyerBanner} alt="poster" height='80px' width='70px' />
                      </figure>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="EnterCustomerDetails ShareDealsBox">
                      <h4>Share this deal</h4>
                      <div className="form-group">
                        <h6>Enter the price</h6>
                        <input type="text" className="form-control" placeholder="Enter Price" />
                      </div>
                      <a className="ConfirmBookingBtn" href={poster} download target="_self" disabled={!poster}>Download Poster</a>
                      <p>* A single .zip file containing all images will be downloaded. You can unzip using free software like WinRar and share with your customers. For faster sharing, use TravClan App.</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ShareDealsPage;

// import React from 'react'

// function ShareDealsPage() {
//   return (
//     <div>ShareDealsPage</div>
//   )
// }

// export default ShareDealsPage
