import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isLoggedIn } from "../../utils/LoggedIn";


export const addBrandDetailApi = createAsyncThunk(
    "addBrandDetailApi/postaddBrandDetailApi",
    async (payload) => {
        console.log("payload===>", payload);
        const response = await axios.post(
            `https://swtclub.com:4300/api/v1/user/brand/add`,
            payload,
            {
                headers: {
                    "Authorization": isLoggedIn("swtadmin")
                },
            }
        );
        return response.data
            ;
    }
);

// 

export const getBrandDetailApi = createAsyncThunk(
    "BrandDetailApi/getBrandDetailApi",
    async (payload) => {
        console.log("payload===>", payload);
        const response = await axios.get(
            `https://swtclub.com:4300/api/v1/user/brand/list`,
            {
                headers: {
                    "Authorization": isLoggedIn("swtadmin")
                },
            }
        );
        return response.data
            ;
    }
);
// Get poster
export const getPoster = createAsyncThunk(
    "getPoster/postgetPoster",
    async (payload) => {
        console.log("payload===>", payload);
        const response = await axios.post(
            `https://swtclub.com:4300/api/v1/user/image`,
            payload,
            {
                headers: {
                    "Authorization": isLoggedIn("swtadmin")
                },
            }
        );
        return response.data
            ;
    }
);