import React from 'react'

const StorySoFar = () => {
    return (
        <div><main id="main" className="ProductMain">
            <section>
                <div className="FilterProductsArea">
                    <div className="AboutArea">
                        <div className="section-title">
                            <h1>Story so far</h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ParentCompanyArea">
                <div className="row">
                    <div className="Outer">
                        <div className="col-lg-6">
                            <figure style={{ marginRight: 25 }}>
                                <img src={require('../assests/images/so-far-2.png')} className="leftImg" />
                            </figure>
                        </div>
                        <div className="col-lg-6 ">
                            <div className="Content" style={{ marginLeft: 25 }}>
                                {/* <h3>Corporis temporibus maiores provident</h3> */}
                                <p className="mb-4">
                                    <strong>Our journey began in 2007</strong>, driven by the passion
                                    to redefine travel experiences for domestic explorers. With a
                                    commitment to providing unmatched holiday solutions, SWT Club
                                    quickly became a trusted name in the industry.
                                </p>
                                <p>
                                    In <strong>2010</strong>, we expanded beyond borders, venturing
                                    into international holiday packages across South East Asia and the
                                    Middle East. This was a significant step in fulfilling our vision
                                    of making global travel accessible and enriching for our clients.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="Outer">
                        <div className="col-lg-6">
                            <div className="Content" style={{ marginRight: 25 }}>
                                {/* <h3>Corporis temporibus maiores provident</h3> */}
                                <p className="mb-4">
                                    The year <strong>2012</strong> marked a transformative milestone
                                    as we officially became a private limited company, setting the
                                    foundation for sustainable growth and robust operations.
                                </p>
                                <p className="mb-4">
                                    A year later, in <strong>2013</strong>, our company’s footprint
                                    extended further into Europe, offering unforgettable tours of the
                                    continent’s most iconic destinations.
                                </p>
                                <p className="mb-4">
                                    By <strong>2014</strong>, we broadened our global reach to include
                                    the USA, Australia, New Zealand, China, and Japan, solidifying our
                                    reputation as a leader in comprehensive international travel
                                    services.
                                </p>
                                <p>
                                    In <strong>2020</strong>, we welcomed Krishna Murthy as Director,
                                    whose strategic vision and leadership propelled SWT Club into an
                                    exciting new era of innovation and excellence. His guidance paved
                                    the way for the diversification of our services, ensuring
                                    continued success.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <figure style={{ marginLeft: 25 }}>
                                <img src={require('../assests/images/so-far-1.png')} className="leftImg" />
                            </figure>
                        </div>
                    </div>
                    <div className="Outer">
                        <div className="col-lg-6">
                            <figure style={{ marginRight: 25 }}>
                                <img src={require('../assests/images/so-far-3.png')} className="leftImg" />
                            </figure>
                        </div>
                        <div className="col-lg-6">
                            <div className="Content" style={{ marginLeft: 25 }}>
                                {/* <h3>Corporis temporibus maiores provident</h3> */}
                                <p className="mb-4">
                                    In <strong>2022</strong>, we took a significant leap forward by
                                    establishing the SWT Academy, an initiative dedicated to skill
                                    development and education. This was further strengthened through
                                    our impactful collaboration with the renowned Tata Institute of
                                    Social Sciences (TISS), reflecting our commitment to empowering
                                    individuals and communities.
                                </p>
                                <p>
                                    As we look to the future in <strong>2025</strong>, our journey
                                    embodies endless possibilities. At SWT Club, we remain dedicated
                                    to exploring new horizons, crafting exceptional experiences, and
                                    inspiring meaningful connections worldwide.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        </div>
    )
}

export default StorySoFar