import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import axios from "axios";
import { isLoggedIn } from "../utils/LoggedIn";
import { fetchStaticContent } from "../reduxToolKit/slices/staticContentApiSlice";
import { useDispatch, useSelector } from "react-redux";
import Header2 from "./Header2";

const Refund = () => {
    const dispatch = useDispatch()
    const privacyPolicy = useSelector((state) => state?.staticContentData?.StaticContentData?.data?.[5])

    const createPrivacyPolicyMarkup = () => {
        return { __html: privacyPolicy?.description };
    };
    useEffect(() => {
        dispatch(fetchStaticContent())
        window.scrollTo({
            top: 0,
            behavior: "smooth", // Smooth scroll animation
        });
    }, [])
    return (
        <div>
            {
                isLoggedIn("swtadmin") ? <Header2 /> : <Header />
            }
            <main id="main" className="ProductMain">
                <section>
                    <div className="FilterProductsArea">
                        <div className="AboutArea mt-5">
                            <div className="section-title">
                                <h1>Refund</h1>
                            </div>
                            <p dangerouslySetInnerHTML={createPrivacyPolicyMarkup()} />
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </div>
    )
}

export default Refund