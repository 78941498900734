import React, { useEffect, useState } from "react";
import Header2 from "./Header2";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import { packageApi } from "../reduxToolKit/slices/packageApiSlice";
import { useLocation } from "react-router-dom";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { faqAction } from "../reduxToolKit/slices/confirmBooking";

const Packages = () => {
  const dispatch = useDispatch();
  const [readMore, setReadMore] = useState(false);
  const [dayRange, setDayRange] = useState([1, 30]);
  const [budgetRange, setBudgetRange] = useState([10000, 1000000]);
  const [title, setTitle] = useState("");
  const [faqList, setFaqList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const { state } = useLocation();
  const [filterData, setFilterData] = useState({
    search: "",
    packageType: "",
    country: "",
    state: "",
  });
  const packageListData = useSelector((state) => state)
  console.log('package list data', packageListData?.packageList)
  useEffect(() => {
    window.scroll(0, 0);

    if (state) {
      dispatch(packageApi({ search: state?.[0] })).then((res) => {
        setPackageList(res.payload.data.result);
      });
      setFilterData({ search: state?.[0] });
    } else {
      dispatch(packageApi({ search: "" })).then((res) => {
        setPackageList(res?.payload?.data?.result);
      });
      setFilterData({ ...filterData, search: "" });
    }

    dispatch(faqAction()).then((res) => {
      setFaqList(res.payload.data.result);
    });
  }, []);

  const handleSearchPackage = () => {
    dispatch(
      packageApi({
        search: filterData?.search,
        numberOfDaysMin: dayRange?.[0],
        numberOfDaysMax: dayRange?.[1],
        budgetMin: budgetRange?.[0],
        budgetMax: budgetRange?.[1],
        packageType: filterData?.packageType,
      })
    ).then((res) => {
      if (res.payload.data.total > 0) {
        setFilterData({ ...filterData, search: "" });
        setPackageList(res.payload.data.result);
        setTitle(filterData?.search);
      } else {
        toast.error("No package found", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    });
  };

  const handleRefresh = () => {
    setFilterData({
      search: "",
      packageType: "",
      country: "",
      state: "",
    });

    setBudgetRange([1000, 10000]);
    setDayRange([1, 2]);
  };

  // useEffect(() => {
  //   console.log()
  //   setPackageList(packageListData?.data?.result)
  // }, [packageListData?.data?.result])

  return (
    <>
      <Header2 />
      <main id="main">
        <section>
          <div className="CountryPackage ">
            <div className="CountryPackageMain">
              <div className="CountryPackageLeft">
                <div className="CountryPackageName">
                  <h1>{packageList?.[0]?.packageTitle} </h1>
                  <h3>
                    <span className="Icon">
                      <img
                        src={require("../assests/images/country_location.png")}
                      />
                    </span>
                    <strong>
                      {title
                        ? title
                        : packageList?.[0]?.sightSeeing?.[0]?.CityName}{" "}
                    </strong>

                    <span className="Package">
                      {" "}
                      {packageList?.length} Package{" "}
                    </span>
                  </h3>
                </div>

                <div
                  dangerouslySetInnerHTML={{
                    __html: readMore
                      ? packageList?.[0]?.packageOverview
                      : packageList?.[0]?.packageOverview?.slice(0, 400),
                  }}
                  className="editor"
                ></div>

                <a
                  onClick={() => {
                    if (readMore) {
                      setReadMore(false);
                      window.scroll(0, 0);
                    } else {
                      setReadMore(true);
                    }
                  }}
                >
                  {readMore ? "Read Less" : "Read More"}
                </a>
              </div>
              <div className="CountryPackageRight">
                <ul>
                  {packageList?.[0]?.packageBanner?.map((ele) => (
                    <li>
                      <span>
                        <img src={ele} />
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="ZoneAreaFilter">
            <div className="row">
              {/* <div className="col-md-2">
                <div className="form-group">
                  <h6>Zone</h6>
                  <select>
                    <option selected="">All</option>
                  </select>
                </div>
              </div> */}
              <div className="col-md-3">
                <div className="form-group days">
                  <h6>
                    No. of days : {dayRange?.[0]}{" "}
                    {dayRange?.length > 0 && "Days"} - {dayRange?.[1]}{" "}
                    {dayRange?.length > 0 && "Days"}
                  </h6>
                  <Slider
                    range
                    min={1}
                    value={dayRange}
                    max={30}
                    onChange={(e) => {
                      setDayRange(e);
                    }}
                  />

                  {/* <img src={require("../assests/images/no_of_days_icon.png")} /> */}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group amount">
                  <h6>
                    Budget :{budgetRange?.length > 0 && "₹"}
                    {budgetRange?.[0]} - {budgetRange?.length > 0 && "₹"}
                    {budgetRange?.[1]}
                  </h6>
                  <Slider
                    range
                    min={10000}
                    value={budgetRange}
                    max={1000000}
                    onChange={(e) => {
                      setBudgetRange(e);
                    }}
                  />

                  {/* <img src={require("../assests/images/no_of_days_icon.png")} /> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <h6>Search by City or Area </h6>

                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Destination"
                    name="location"
                    value={filterData?.search}
                    onChange={(e) =>
                      setFilterData({ ...filterData, search: e.target.value })
                    }
                  />
                  {/* <select onChange={handleSearch} value={filterData?.search}>
                    <option value={""}>All</option>
                    <option value="Mumbai">Mumbai</option>
                    <option value="Pune">Pune</option>
                    <option value="Goa">Goa</option>
                    <option value="Chennai">Chennai</option>
                    <option value="Bangalore">Bangalore</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Hyderabad">Hyderabad</option>
                    <option value="Nainital">Nainital</option>
                  </select> */}
                </div>
              </div>
              <div className="col-md-2">
                <div className="form-group">
                  <h6>Package Type</h6>
                  <select
                    className="form-control"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        packageType: e.target.value,
                      })
                    }
                    value={filterData?.packageType}
                  >
                    <option select="">Package Type</option>
                    <option value={"Group package"}>Group package</option>
                    <option value={"Single package"}>Single package</option>
                  </select>
                </div>
              </div>

              <div className="col-md-2">
                <div className="form-group packageSearch">
                  <h6>&nbsp;</h6>
                  <a onClick={handleSearchPackage} className="SearchBtn">
                    Search
                  </a>
                  &nbsp; &nbsp; &nbsp;
                  <a
                    onClick={handleRefresh}
                    className="form-group packageSearch"
                  >
                    <i class="fa fa-refresh"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="FlightListingArea">
          <div className="row">
            {packageList?.map((item) => (
              <div className="col-md-6">
                <Link to={`/package_details/${item?._id}`} >
                  <div className="BudgetBox">
                    <div className="BudgetBoxImg">
                      <img src={item?.packageBanner?.[0]} />
                      {/* <span className="Blue">{ele?.roomType}</span> */}
                      <span className="GroupTour">{item?.packageType}</span>
                    </div>
                    <div className="BudgetBoxContent">
                      <div className="BudgetBoxContentTop">
                        <span className="date">
                          Valid upto{" "}
                          {moment(new Date())
                            .add(
                              item?.numberOfNights?.totalNumberofNights + 1,
                              "days"
                            )
                            ?.format("ll")}
                        </span>
                        <h4>
                          {" "}
                          {item?.numberOfNights?.totalNumberofNights + 1} Days{" "}
                          {item?.numberOfNights?.totalNumberofNights} Nights
                        </h4>
                      </div>
                      <h4>{item?.packageTitle}...</h4>
                      <p>
                        {item?.numberOfNights?.city?.map(
                          (item, index) =>
                            `${index == 0 ? "" : ","} ${item?.name}(${item?.noOfNights
                            }N)`
                        )}
                      </p>
                      <ul>
                        <li>
                          <span>
                            <img
                              src={require("../assests/images/van_icon.png")}
                            />
                          </span>
                          {item?.packageTransportationType}
                        </li>
                        <li>
                          <span>
                            <img
                              src={require("../assests/images/winter_icon.png")}
                            />
                          </span>
                          {item?.season} Special
                        </li>
                      </ul>
                      <h2 className="Price">
                        INR {Number(item?.packagePrice?.[0]?.pricePerHead ?? 0) + Number(item?.packagePrice?.[0]?.tripleSharePrice ?? 0)}{" "}
                        <span>/person</span>
                      </h2>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </section>
        <section>
          <div className="ThemePackages">
            <h2>
              {" "}
              {title
                ? title
                : packageList?.[0]?.sightSeeing?.[0]?.CityName}{" "}
              packages by theme
            </h2>
            <div className="row">
              {packageList?.[0]?.optionalInformation?.map((item) => (
                <div className="col-md-3">
                  <div className="ThemePackagesBox">
                    <figure>
                      <img src={item?.uploadBanner?.[0]} />
                      <span className="Overlay">POPULAR</span>
                    </figure>
                    <div className="ThemePackagesContent">
                      <h3>{item?.title}</h3>
                      <span>
                        <i className="fa-solid fa-arrow-right" />
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
        <section>
          <div className="PlacesToVisit">
            <h2>
              Places to visit in{" "}
              {title ? title : packageList?.[0]?.sightSeeing?.[0]?.CityName}{" "}
            </h2>
            <ul>
              {packageList?.map((item) => (
                <li>
                  <figure>
                    <img src={item?.packageBanner?.[0]} />
                    <figcaption>
                      Breakfast With Orangutans In Bali Zoo
                    </figcaption>
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section>
          <div className="PlacesToVisit Activities">
            <h2>
              Activities in{" "}
              {title ? title : packageList?.[0]?.sightSeeing?.[0]?.CityName}{" "}
            </h2>
            <ul>
              {packageList?.[0]?.sightSeeing?.map((item) => (
                <li>
                  <figure>
                    <img src={item?.sightInfo?.[0]?.sightSeeingBanner?.[0]} />
                    <figcaption>
                      {item?.sightInfo?.[0]?.sightseeingTitle}
                    </figcaption>
                  </figure>
                </li>
              ))}
            </ul>
          </div>
        </section>
        <section>
          <div className="FaqQuestions">
            <div className="section-title">
              <h2>
                Frequently asked questions
              </h2>
            </div>
            <div className="StaticArea">
              <div className="panel-group" id="accordion">
                {faqList?.map((item, i) => (
                  <div className="panel">
                    <div
                      className="panel-heading"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href={`#collapse${i}`}
                      aria-expanded="true"
                    >
                      <h4>
                        <span>{i + 1}</span> {item?.faqName}
                      </h4>
                    </div>
                    <div
                      id={`collapse${i}`}
                      className="panel-collapse collapse show in"
                    >
                      <div className="panel-body">
                        <p
                          dangerouslySetInnerHTML={{
                            __html: item?.description,
                          }}
                          className="editor"
                        ></p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </section>

        {/*
        <section>
          <div className="RelatedPackages">
            <h2>Related packages</h2>
            <div className="row">
              <div className="col-md-6">
                <div className="BudgetBox">
                  <div className="BudgetBoxImg">
                    <img src={require("../assests/images/tour_img_1.png")} />
                  </div>
                  <div className="BudgetBoxContent">
                    <div className="BudgetBoxContentTop">
                      <span className="date">Valid upto 31 Mar 2024</span>
                      <h4>7 Days 6 Nights</h4>
                    </div>
                    <h4>Simply Singapore with Beautiful Bali and...</h4>
                    <p>
                      Singapore (1N), Cruise (3N), Singapore (3N), Bali Island
                      (2N), Bali Island (2N)
                    </p>
                    <ul>
                      <li>
                        <span>
                          <img
                            src={require("../assests/images/van_icon.png")}
                          />
                        </span>
                        Private Van Tour
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../assests/images/winter_icon.png")}
                          />
                        </span>
                        Winter Special
                      </li>
                    </ul>
                    <h2 className="Price">
                      INR 1,08,000 <span>/person</span>
                    </h2>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="BudgetBox">
                  <div className="BudgetBoxImg">
                    <img src={require("../assests/images/tour_img_2.png")} />
                  </div>
                  <div className="BudgetBoxContent">
                    <div className="BudgetBoxContentTop">
                      <span className="date">Valid upto 31 Mar 2024</span>
                      <h4>7 Days 6 Nights</h4>
                    </div>
                    <h4>Simply Singapore with Beautiful Bali and...</h4>
                    <p>
                      Singapore (1N), Cruise (3N), Singapore (3N), Bali Island
                      (2N), Bali Island (2N)
                    </p>
                    <ul>
                      <li>
                        <span>
                          <img
                            src={require("../assests/images/van_icon.png")}
                          />
                        </span>
                        Private Van Tour
                      </li>
                      <li>
                        <span>
                          <img
                            src={require("../assests/images/winter_icon.png")}
                          />
                        </span>
                        Winter Special
                      </li>
                    </ul>
                    <h2 className="Price">
                      INR 1,08,000 <span>/person</span>
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
      </main>
      <Footer />
    </>
  );
};

export default Packages;
