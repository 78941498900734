import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { addBrandDetailApi, getBrandDetailApi } from "../reduxToolKit/slices/BrandDetail";
import { uploadImageFunction } from "../utils/uploadImage";


const initialState = {
  id: "",
  companyName: "",
  contactPerson: "",
  contactNumber: "",
  emailId: "",
  organisationWebsite: "",
  logo: ""
}
const BrandDetails = () => {
  const [iState, updateState] = useState(initialState);
  const { id, companyName, contactPerson, contactNumber, emailId, organisationWebsite, logo } = iState;
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({
      ...iState,
      [name]: value
    })
  }
  const handleImgUpload = async (e) => {
    window.Buffer = window.Buffer || require("buffer").Buffer;
    const { name, value } = e.target
    const file = e.target.files[0]
    const res = await uploadImageFunction(file, name, updateState, iState)
    console.log('res', res)
  }

  const handleClick = () => {
    const data = {
      id,
      companyName,
      contactPerson,
      contactNumber,
      emailId,
      organisationWebsite,
      logo
    }
    dispatch(addBrandDetailApi(data)).then((res) => {
      console.log(res);
    })
  }


  useEffect(() => {
    dispatch(getBrandDetailApi()).then((res) => {
      console.log(res)
      if (res?.payload?.status === 200) {
        updateState({
          ...iState,
          id: res?.payload?.data?.[0]?._id,
          companyName: res?.payload?.data?.[0]?.companyName,
          contactPerson: res?.payload?.data?.[0]?.contactPerson,
          contactNumber: res?.payload?.data?.[0]?.contactNumber,
          emailId: res?.payload?.data?.[0]?.emailId,
          organisationWebsite: res?.payload?.data?.[0]?.organisationWebsite,
          logo: res?.payload?.data?.[0]?.logo
        })
      }
    })
  }, [])

  return (
    <div>
      <section>
        <div className="ContactFormArea ContactArea">
          <div className="container">
            <div className="ContactFormBody BrandDetails">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="NewTitleHeading">
                      <h3>Brand Detail</h3>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <div className="form-group">
                      <h6>Company Name</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter company name"
                        name="companyName"
                        value={companyName}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Contact Person</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Person Name"
                        name="contactPerson"
                        value={contactPerson}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Contact Number</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Number"
                        name="contactNumber"
                        value={contactNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Email Id</h6>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Id"
                        name="emailId"
                        value={emailId}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Organisation Website</h6>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Organisation Website"
                        name="organisationWebsite"
                        value={organisationWebsite}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-group">
                      <h6>Logo</h6>
                      <div className="BrandDetailsLogo">
                        {/* <span>
                         
                          </span> */}
                        <input type="file" className="form-control" name="logo" onChange={handleImgUpload} />
                        {/* <i class="fa-solid fa-pencil"></i> */}
                        <img src={logo} />
                      </div>
                    </div>
                    <a className="form-control" onClick={handleClick}> {id ? 'Update' : 'Add'}</a>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

    </div>
  );
};

export default BrandDetails;
